import { ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const SideDrawerItem = ({ text, path, link, label, download }) => {
  if (link !== undefined) {
    return (
      <a
        href={link}
        target="_blank"
        aria-label={label}
        rel="noreferrer"
        download={download ? true : null}
      >
        <ListItem button key={text} align="center" sx={{ marginY: "auto" }}>
          <ListItemText
            primary={<Typography variant="subtitle1">{text}</Typography>}
            align="center"
          />
        </ListItem>
      </a>
    );
  }

  return (
    <Link to={path}>
      <ListItem button key={text} align="center" sx={{ marginY: "auto" }}>
        <ListItemText
          primary={<Typography variant="subtitle1">{text}</Typography>}
          align="center"
        />
      </ListItem>
    </Link>
  );
};

export default SideDrawerItem;
