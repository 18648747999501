import { Link } from "@mui/icons-material";
import { Icon, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { txExplorerUrl } from "../../config/wallet/setupNetwork";
import mintStyle from "./Mint.module.css";
import CloseIcon from "@mui/icons-material/Close";
export default function MintModal({
  title,
  body,
  transactionHash,
  dismissOnBackdrop = true,
  handleCloseModal,
}) {
  const explorerUrl = `${txExplorerUrl}${transactionHash}`;

  const visitTx = () => {
    window.open(explorerUrl, "_blank");
  };

  return (
    <Modal open={body} onClose={() => dismissOnBackdrop && handleCloseModal()}>
      <Box
        bgcolor="background.paper"
        p={4}
        className={mintStyle["modal-box-wrapper"]}
        width={{ xs: "90%", sm: "90%", md: "auto" }}
      >
        {dismissOnBackdrop && (
          <Icon
            component={CloseIcon}
            fontSize="large"
            color="error"
            className={mintStyle["modal-box-close"]}
            onClick={handleCloseModal}
          />
        )}
        <Typography variant="h3" textAlign="center">
          {title}
        </Typography>
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="center"
          variant="subtitle1"
          sx={{ mt: 2 }}
        >
          {body}
        </Typography>
        {transactionHash && (
          <Box
            className={mintStyle["modal-visit-tx"]}
            component="button"
            onClick={visitTx}
          >
            <Link style={{ marginRight: 10 }} />
            <Typography variant="button">
              View on Blockchain Explorer
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
