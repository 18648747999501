import { Box, Typography } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import { METAMASK_CONNECTOR_ID, METAMASK_LOGO } from "../../config/constants";
import { connect } from "../../config/wallet/connector";
import connectWalletStyle from "./ConnectWallet.module.css";

export default function Metamask({ handleCloseModal }) {
  const { activate } = useWeb3React();

  const handleConnect = async () => {
    handleCloseModal();
    await connect(METAMASK_CONNECTOR_ID, activate);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <button
        onClick={handleConnect}
        className={connectWalletStyle["wallet-button"]}
      >
        <img
          src={METAMASK_LOGO}
          alt="Metamask"
          className={connectWalletStyle["wallet-logo"]}
        />
        <Typography variant="button">Metamask</Typography>
      </button>
    </Box>
  );
}
