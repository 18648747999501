export const METAMASK_CONNECTOR_ID = "metamask";
export const WALLETCONNECT_CONNECTOR_ID = "walletconnect";

export const METAMASK_LOGO = "./metamask.png";
export const WALLETCONNECT_LOGO = "./walletconnect.png";

export const NFT_CONTRACT_ADDRESS = "0xB67342688Ca1fc5962172f8C297583b891340e55";
export const NFT_PRESALE_MINT_METHOD = "presaleMint";
export const NFT_PUBLIC_MINT_METHOD = "mint";

export const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
export const PROVIDER_URL = process.env.REACT_APP_PROVIDER_URL;

export const IS_SALE_IN_PROGRESS = process.env.REACT_APP_SALE_IN_PROGRESS === "true";