import { Box } from "@mui/material";
import React from "react";
import * as imgs from "../../../../assets/imgs/nfts/index";
import Nft from "./Nft";

const sortedImages = Object.values(imgs).slice(0, 12);
const backwardSortedImages = Object.values(imgs).slice(1, 13).reverse();

const NftCollection = ({ sx, ...props }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center",...sx }}>
      <Box
        sx={{ display: "flex", paddingBottom: 2, transform: "translateX(-2%)" }}
      >
        {sortedImages.map((img) => (
          <Box key={img} sx={{ paddingX: 2 }}>
            <Nft img={img} />
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", transform: "translateX(1%)" }}>
        {backwardSortedImages.map((img) => (
          <Box key={img} sx={{ paddingX: 2 }}>
            <Nft img={img} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NftCollection;
