import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Logo from "../../../assets/imgs/logo.png";
import React from "react";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import { useLocation } from "react-router-dom";
import { NFT_CONTRACT_ADDRESS } from "../../../config/constants";

const Footer = () => {
  const location = useLocation();

  return (
    <Container
      maxWidth="100%"
      sx={{
        paddingX: 0,
        m: 0,
        backgroundColor: location.pathname === "/faq" ? "#0a1614" : "#230f28",
      }}
    >
      <Grid component={Container} container maxwidth="lg" py={4}>
        <Grid item xs={12} md={2.05} lg={3} align="left">
          <Box
            component="img"
            src={Logo}
            maxHeight="5rem"
            sx={{ display: { xs: "none", md: "flex" }, marginRight: 3 }}
          />
        </Grid>
        <Grid item xs={12} md={7.9} lg={6} align="center" alignSelf="center">
          <Typography variant="body1" color="white" gutterBottom>
            Smart contract address:
          </Typography>
          <Typography variant="body1" sx={{ color: "#f0a800", overflowWrap: "break-word" }} noWrap={false}>
            {NFT_CONTRACT_ADDRESS}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.05} lg={3} align="center" alignSelf="center">
          <SocialMediaIcons />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
