import { Box, Button, Modal, Typography } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import Metamask from "./Metamask";
import WalletConnect from "./WalletConnect";
import connectWalletStyle from "./ConnectWallet.module.css";

export default function ConnectWalletModal({ isOpen, handleCloseModal }) {
  const { active, account, deactivate } = useWeb3React();

  const disconnect = () => {
    deactivate();
    handleCloseModal();
  };

  const NotConnected = () => {
    return (
      <Box
        className={connectWalletStyle["modal-box-wrapper"]}
        bgcolor="background.paper"
        p={4}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Metamask handleCloseModal={handleCloseModal} />
        <WalletConnect handleCloseModal={handleCloseModal} />
      </Box>
    );
  };

  const Connected = () => {
    return (
      <Box
        className={connectWalletStyle["modal-box-wrapper"]}
        bgcolor="background.paper"
        p={4}
        flexDirection="column"
        width={{ xs: "90%", sm: "auto" }}
      >
        <Typography variant="h3">Your Wallet</Typography>
        <Typography
          variant="subtitle1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {account}
        </Typography>
        <Button variant="text" color="warning" onClick={disconnect}>
          Disconnect
        </Button>
      </Box>
    );
  };

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      {active ? <Connected /> : <NotConnected />}
    </Modal>
  );
}
