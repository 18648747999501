import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Background from "../../../assets/imgs/landing-page/the-team-bg.png";
import { Box } from "@mui/system";
import Member from "./Member";
import * as imgs from "../../../assets/imgs/landing-page/team/index";

const teamMembers = [
  {
    img: imgs.i7,
    title: "Peppergrain",
    text: "Head of club affairs and chief rasta",
  },
  { img: imgs.i3, title: "JFRICO", text: "Art Director Extraordinaire" },
  { img: imgs.i2, title: "Andrei Statescu", text: "Blockchain Developer" },
  { img: imgs.i4, title: "Sergiu Radu", text: "Web Developer" },
  { img: imgs.i5, title: "Teaby", text: "Tech Lead" },
];

const TheTeam = () => {
  return (
    <Container
      id="team"
      maxWidth="100%"
      sx={{
        width: "100%",
        backgroundImage: `url(${Background})`,
        backgroundSize: "3840px 100%",
        backgroundPosition: "bottom center",
        overflowX: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Box paddingY={15}>
          <Typography
            variant="h2"
            align="center"
            sx={{ fontSize: "70px", color: "#ddd" }}
          >
            MEET
          </Typography>
          <Typography variant="h2" align="center">
            THE TEAM
          </Typography>
        </Box>

        <Grid container justifyContent="center" spacing={4} marginBottom={10}>
          {teamMembers.map((member) => (
            <Grid key={member.img} item xs={12} sm={6} md={4}>
              <Member
                key={member.img}
                img={member.img}
                title={member.title}
                text={member.text}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};
export default TheTeam;
