const phaseData = {
  phase1: [
    {
      checked: true,
      item: "Artwork collection creation",
    },
    {
      checked: true,
      item: "Smart contract development",
    },
    {
      checked: true,
      item: "Establish social media accounts",
    },
    {
      checked: true,
      item: "Website development",
    },
    {
      checked: true,
      item: "Launch Rasta Beach Club Discord",
    },
    {
      checked: true,
      item: "Reward the growing Beach Club posse with NFT giveaways, special roles, and beach club pass for early check in",
    },
  ],
  phase2: [
    {
      checked: false,
      item: "Free mint",
    },
  ],
  phase3: [
    {
      checked: false,
      item: "Non-profit donations voted on by holders",
    },
    {
      checked: false,
      item: "Metaverse land purchase and creation of Rasta Beach Club Meta",
    },
    {
      checked: false,
      item: `Metaverse concerts with exclusive holder "backstage" access`,
    },
    {
      checked: false,
      item: "All expense paid Caribbean Beach club giveaways to lucky posse members",
    },
    {
      checked: false,
      item: "Exclusive merch store Launch for posse members",
    },
    {
      checked: false,
      item: "Automatic whitelisting for new NFT drops",
    },
    {
      checked: false,
      item: "Exclusive art gallery app",
    },
  ],
};

export default phaseData;