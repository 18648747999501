import React from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { SiDiscord } from "react-icons/si";
import { BsMedium } from "react-icons/bs";
import { IconButton } from "@mui/material";
const SocialMediaIcons = ({ marginX, marginRight, marginY }) => {
  const FONT_SIZE = "1.8rem";
  const defaultMarginX = 0.2;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginRight: marginRight ?? "0",
        marginY: marginY ?? "0",
      }}
    >
      <a
        href="https://www.instagram.com/rastabeachclub"
        target="_blank"
        rel="noreferrer"
      >
        <IconButton size="medium" sx={{ mx: marginX ?? defaultMarginX }}>
          <InstagramIcon sx={{ fontSize: FONT_SIZE }} />
        </IconButton>
      </a>

      <a href="https://discord.gg/4kVJ4ECSuH" target="_blank" rel="noreferrer">
        <IconButton size="medium" sx={{ mx: marginX ?? defaultMarginX }}>
          <SiDiscord style={{ fontSize: FONT_SIZE }} />
        </IconButton>
      </a>

      <a
        href="https://www.twitter.com/rastabeachclub"
        target="_blank"
        rel="noreferrer"
      >
        <IconButton size="medium" sx={{ mx: marginX ?? defaultMarginX }}>
          <TwitterIcon sx={{ fontSize: FONT_SIZE }} />
        </IconButton>
      </a>

      <a href="https://t.me/rastabeachclub" target="_blank" rel="noreferrer">
        <IconButton size="medium" sx={{ mx: marginX ?? defaultMarginX }}>
          <TelegramIcon sx={{ fontSize: FONT_SIZE }} />
        </IconButton>
      </a>

      <a
        href="https://medium.com/@rastabeachclub"
        target="_blank"
        rel="noreferrer"
      >
        <IconButton size="medium" sx={{ mx: marginX ?? defaultMarginX }}>
          <BsMedium style={{ fontSize: FONT_SIZE }} />
        </IconButton>
      </a>
    </div>
  );
};

export default SocialMediaIcons;
