import React from "react";
import { Button } from "@mui/material";
const BlackButton = ({ children, sx, ...props }) => {
  return (
    <Button
      size="large"
      variant="contained"
      color="black"
      fullWidth={false}
      sx={{
        my: 1,
        py: 1.5,
        color: "white",
        backgroundColor: "#000",
        whiteSpace: "nowrap",
        minWidth: "auto",
        "&:hover": { backgroundColor: "#111" },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BlackButton;
