import { ThemeProvider } from "@mui/material";
import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Web3 from "web3";
import App from "./App";
import { ConnectWalletModalProvider } from "./context/ConnectWalletContext";
import "./index.css";
import { theme } from "./theme/theme";

const getLibrary = (_provider) => {
  return new Web3(_provider);
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ConnectWalletModalProvider>
            <App />
          </ConnectWalletModalProvider>
        </Web3ReactProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
