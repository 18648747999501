import React from "react";
import RastaBeachClub from "./RastaBeachClub/RastaBeachClub";
import Roadmap from "./Roadmap/Roadmap";
import TheCollection from "./TheCollection/TheCollection";
import TheTeam from "./TheTeam/TheTeam";

const LandingPage = () => {
  return (
    <>
      <RastaBeachClub />
      <TheCollection />
      <Roadmap />
      <TheTeam />
    </>
  );
};

export default LandingPage;
