import React, { createContext, useContext, useState } from "react";
import ConnectWalletModal from "../components/ConnectWallet/ConnectWalletModal";

const ConnectWalletContext = createContext(null);

const ConnectWalletModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function closeConnectWalletModal() {
    setIsOpen(false);
  }

  function openConnectWalletModal() {
    setIsOpen(true);
  }

  return (
    <ConnectWalletContext.Provider
      value={{ isOpen, openConnectWalletModal, closeConnectWalletModal }}
    >
      {children}
      <ConnectWalletModal
        isOpen={isOpen}
        handleCloseModal={closeConnectWalletModal}
      />
    </ConnectWalletContext.Provider>
  );
};

const useConnectWalletContext = () => {
  return useContext(ConnectWalletContext);
};

export { ConnectWalletModalProvider, useConnectWalletContext };
