import { UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { CHAIN_ID, WALLETCONNECT_CONNECTOR_ID } from "../constants";
import { networkConfigs, nodes } from "./setupNetwork";

export const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_ID],
});

export const walletconnect = () =>
  new WalletConnectConnector({
    rpc: { [CHAIN_ID]: nodes[CHAIN_ID][0] },
    chainId: CHAIN_ID,
    supportedChainIds: [CHAIN_ID],
    qrcode: true,
  });

const requestSwitchNetwork = async () => {
  try {
    const provider = window.ethereum;
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
    });
    return true;
  } catch (error) {
    return false;
  }
};

const requestAddNetwork = async () => {
  try {
    const provider = window.ethereum;
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [networkConfigs[CHAIN_ID]],
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const connect = async (connectorId, activate) => {
  if (connectorId === WALLETCONNECT_CONNECTOR_ID) {
    await activate(walletconnect());
  } else {
    const provider = window.ethereum;
    if (!provider) {
      window.open(
        `https://metamask.app.link/dapp/${window.location.href}`,
        "_blank"
      );
      return;
    }
    await activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        const isSwitchSuccess = await requestSwitchNetwork();
        if (isSwitchSuccess) {
          activate(injected);
          return;
        }
        const isSuccess = await requestAddNetwork();
        if (isSuccess) {
          activate(injected);
          return;
        }
      }
    });
  }
};
