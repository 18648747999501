import {
  CircularProgress,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import BlackButton from "../../UI/BlackButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import * as imgs from "../../../assets/imgs/landing-page/banner/index";

const backgroundColors = ["#efad7c", "#e8bfff", "#78d1fc", "#e24c50"];
const RastaBeachClub = () => {
  const [loading, setLoading] = useState(true);
  const [randomImage, setRandomImage] = useState(null);
  const [randomColor, setRandomColor] = useState(null);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    const images = Object.values(imgs);
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
    setRandomColor(
      backgroundColors[Math.floor(Math.random() * backgroundColors.length)]
    );
  }, []);

  const mediumContent = (
    <Grid container columnSpacing={10}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: loading ? "center" : "flex-end",
          alignItems: loading ? "center" : "flex-end",
        }}
      >
        <Box
          sx={{ display: loading ? "none" : "block" }}
          component="img"
          alt="rasta-banner"
          src={randomImage}
          onLoad={() => setLoading(false)}
          width="620px"
        ></Box>
        {loading && <CircularProgress />}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box>
          <Typography variant="h2" sx={{ marginLeft: 1, fontSize: "150px" }}>
            RASTA BEACH
          </Typography>
          <Typography
            variant="h1"
            sx={{ lineHeight: "70%", fontSize: "450px" }}
          >
            CLUB
          </Typography>
        </Box>

        <Typography
          component="div"
          sx={{ maxWidth: "500px" }}
          variant="subtitle1"
          my={5}
          paragraph={true}
        >
          Rasta Beach Club is a generative NFT art collection. The collection
          combines over 140 individual traits to create a super cool collection
          of characters that are unique in their own way! The Rasta Beach Club
          NFT collection is all about good vibes only through its eclectic cast
          of characters.
        </Typography>

        <Link to="/faq" style={{ textDecoration: "none", color: "inherit" }}>
          <BlackButton
            fullWidth={false}
            sx={{ width: "200px", marginBottom: "4rem" }}
          >
            Learn More
          </BlackButton>
        </Link>
      </Grid>
    </Grid>
  );

  const smallContent = (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box>
        <Typography
          variant="h1"
          sx={{ fontSize: isSmall ? "5rem" : "7rem", mt: 3 }}
          textAlign="center"
        >
          RASTA BEACH CLUB
        </Typography>
      </Box>

      <Typography
        component="div"
        sx={{ maxWidth: "500px" }}
        variant="subtitle1"
        my={1}
        paragraph={true}
        textAlign="center"
      >
        Rasta Beach Club is a generative NFT art collection. The collection
        combines over 140 individual traits to create a super cool collection of
        characters that are unique in their own way! The Rasta Beach Club
        NFT collection is all about good vibes only through its eclectic cast of
        characters.
      </Typography>

      <Link to="/faq" style={{ textDecoration: "none", color: "inherit" }}>
        <BlackButton fullWidth={false} sx={{ width: "200px", marginBottom: 3 }}>
          Learn More
        </BlackButton>
      </Link>

      <Box
        sx={{
          display: loading ? "none" : "block",
          transform: "translateX(-5%)",
        }}
        component="img"
        alt="rasta-banner"
        src={randomImage}
        onLoad={() => setLoading(false)}
        width={isSmall ? "80%" : "50%"}
      ></Box>
      {loading && <CircularProgress sx={{ my: 20 }} />}
    </Container>
  );

  return (
    <>
      {randomColor && (
        <Container
          id="Top"
          maxWidth="100%"
          sx={{
            height: "100%",
            backgroundColor: randomColor,
            overflowX: "hidden",
          }}
        >
          {isLarge && <Toolbar />}
          <Toolbar />

          {isMedium ? mediumContent : smallContent}
        </Container>
      )}
    </>
  );
};

export default RastaBeachClub;
