import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
export const CheckedCircle = () => {
  return (
    <Box
      component="div"
      sx={{
        borderRadius: "50%",
        backgroundColor: "#f0a800",
        height: "22px",
        width: "22px",
        minHeight: "22px",
        minWidth: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 2,
      }}
    >
      <CheckIcon sx={{ height: "18px", width: "18px", color: "primary.main" }} />
    </Box>
  );
};
