import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 940,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",

    button: {
      textTransform: "none",
      fontSize: 16,
    },
    h1: {
      fontFamily: "Devant Horgen, sans-serif",
      fontSize: 250,
      lineHeight: "100%",
    },
    h2: {
      fontFamily: "Devant Horgen, sans-serif",
      fontSize: 100,
      lineHeight: "90%",
    },
    h3: {
      fontFamily: "Devant Horgen, sans-serif",
      fontSize: 50,
      lineHeight: "85%",
    },
    h4: {
      fontFamily: "Devant Horgen, sans-serif",
    },
    h5: {
      fontFamily: "Devant Horgen, sans-serif",
    },
    h6: {
      fontFamily: "Devant Horgen, sans-serif",
    },
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      fontSize: 16,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 15,
    },
  },

  palette: {
    mode: "dark",
    background: {
      default: "#210f27",
    },
    primary: {
      main: "#210f27",
    },
    white: {
      main: "#fff",
    },
    black: {
      main: "#000",
      light: "#ccc",
    },
    transparent: "transparent",
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "none",
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "none",
          transition: "all 0.3s ease-in-out",
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
  },
});

theme = createTheme(theme, {
  typography: {
    h3: {
      fontSize: 50,
      [theme.breakpoints.down("sm")]: {
        fontSize: 45,
      },
    },

    subtitle1: {
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    body1: {
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
});
export { theme };
