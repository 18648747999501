import { Box, Typography } from "@mui/material";
import React from "react";
import { CheckedCircle } from "./CheckedCircle";
import { UncheckedCircle } from "./UncheckedCircle";
import cardBg1 from "../../../../assets/imgs/mint/card-bg-1.png";
import cardBg2 from "../../../../assets/imgs/mint/card-bg-2.png";
import cardBg3 from "../../../../assets/imgs/mint/card-bg-3.png";
import "./List.css";

const StepBox = ({ title, isMedium, bgItem, checkBoxes, lastItem }) => {
  const backgrounds = [cardBg1, cardBg2, cardBg3];
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexShrink: 2,
        flexDirection: "column",
        alignItems: "center",
        height: "600px",
        width: isMedium ? "48%" : "65%",
        minWidth: isMedium ? "20%" : "280px",
        backgroundImage: `url(${backgrounds[bgItem]})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 40%",
        backgroundColor: "#0005",
        backgroundBlendMode: "darken",
        borderRadius: 1,
        marginX: "auto"
      }}
    >
      <Box
        component="div"
        sx={{
          height: isMedium ? "22%" : "15%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" align="center" >
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Box
        className="stepbox-content"
        component="div"
        sx={{
          height: isMedium ? "78%" : "85%",
          width: "100%",
          backgroundColor: "primary.main",
          borderRadius: "0 0 5px 5px",
          overflow: "auto"
        }}
      >
        <ol className="stepper">
          {checkBoxes?.map((checkBox, id) => (
            <li
              key={id}
              className={`step-item ${checkBox.checked ? "done" : ""}`}
            >
              {checkBox.checked ? <CheckedCircle /> : <UncheckedCircle />}
              <span>{checkBox.item}</span>
            </li>
          ))}
        </ol>
        {lastItem && 
          <Box component="div" sx={{
            display: "flex", 
            height: "90%", 
            justifyContent: "center", 
            alignItems: "center"
            }}
          > 
            <Typography variant="h3" align="center">
              Shhhhhhhh!
            </Typography> 
          </Box>
        }
      </Box>
    </Box>
  );
};

export default StepBox;
