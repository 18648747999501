import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";

const Member = ({ img, title, text }) => {
  return (
    <Card
      elevation={0}
      sx={{ boxShadow: "none", maxWidth: { xs: "100%", md: "350px" } }}
    >
      <CardMedia component="img" width="215px" image={img} alt="team-member" />
      <CardContent sx={{ backgroundColor: "#fff", p: "10px" }} align="center">
        <Typography color="black.main" variant="h3">
          {title.toUpperCase()}
        </Typography>
        <Divider sx={{ backgroundColor: "#ccc", width: "25%" }} align="" />
        <Typography color="darkgrey" variant="body1">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Member;
