import { Typography, Box, Button } from "@mui/material";
import * as React from "react";
import Accordion from "./Accordion";

export default function Accordions() {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div style={{ marginBottom: "3rem" }}>
      {accordionElements.map((el) => (
        <Accordion
          key={el.summary}
          summary={el.summary}
          details={el.details}
          children={el.children ?? null}
          expanded={expanded === el.id}
          onChange={handleChange(el.id)}
        />
      ))}
    </div>
  );
}

const accordionElements = [
  {
    id: 1,
    summary: "What is the Rasta Beach NFT?",
    details: `Rasta Beach Club is comprised of 10,000 NFTs. A digital art collection on the Ethereum Blockchain. Rasta Beach Club NFT is a generative art collection characterized by fun multiculturalism with a conscientious 
    roadmap that immediately rewards participants and supports meaningful charitable activities.`,
  },
  {
    id: 2,
    summary: "When will be able to mint Rasta Beach Club NFTs?",
    children: (
      <>
        <Typography color="black.main" variant="body1">
          The dates for private and public minting will be announced through our
          community social platforms. Please ensure that you join us on of these
          platforms to stay up to date. They are:{" "}
        </Typography>
        <Box
          align="center"
          sx={{
            color: "black.main",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            whiteSpace: "nowrap",
            minWidth: "auto",
            mt: 2,
          }}
        >
          <Button
            align="center"
            component="a"
            href="https://t.me/rastabeachclub"
            target="_blank"
            rel="noreferrer"
          >
            Telegram
          </Button>
          <Button
            align="center"
            component="a"
            href="https://discord.gg/4kVJ4ECSuH"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </Button>
          <Button
            align="center"
            component="a"
            href="https://www.instagram.com/rastabeachclub"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </Button>
          <Button
            align="center"
            component="a"
            href="https://www.twitter.com/rastabeachclub"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </Button>
          <Button
            align="center"
            component="a"
            href="https://medium.com/@rastabeachclub"
            target="_blank"
            rel="noreferrer"
          >
            Medium
          </Button>
        </Box>
      </>
    ),
  },
  {
    id: 3,
    summary: "Which blockchain will the project be launched on?",
    details: `Rasta Beach Club NFTs will be launched on the Ethereum blockchain.`,
  },
  {
    id: 4,
    summary: "Can I mint a Rasta Beach NFT on my phone?",
    details: `You will be able to mint on your phone but we highly recommend that you mint on a desktop computer instead as it is safer and faster.`,
  },
  {
    id: 5,
    summary: "How many attributes does the collection have?",
    details: `The Rasta Beach Club NFT collection has over 140 traits.`,
  },
  {
    id: 6,
    summary: "Do you have different rarities?",
    details: `Yes.`,
  },
  {
    id: 7,
    summary: "Are there any gaming or metaverse plans the collection?",
    details: `Yes there are plans to build multiple metaverse experiences that will be exclusive for Rasta Beach Club NFT holders.`,
  },
  {
    id: 8,
    summary: "What specific charities will the project be supporting?",
    details: `The specific charities will be announced and we will give holders a chance to vote on which charities will be supported.`,
  },
];
