import { Container, Typography, Box, Grid } from "@mui/material";
import React from "react";
import CircleWave1 from "../../../assets/imgs/landing-page/circle-wave1.png";
import BlackButton from "../../UI/BlackButton";
import NftCollection from "./NftCollection/NftCollection";
import * as imgs from "../../../assets/imgs/landing-page/collection/index";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const TheCollection = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Container
      id="about"
      maxWidth="100%"
      align="center"
      sx={{
        height: "100%",
        backgroundImage: `url(${CircleWave1})`,
        backgroundPosition: "80% 80%",
        backgroundSize: "2600px",
        overflowX: "hidden",
      }}
    >
      <Box paddingTop={15} width="700px" maxWidth="100%">
        <Typography
          color="darkGray"
          variant="h2"
          align="center"
          sx={{ fontSize: "70px" }}
        >
          ABOUT
        </Typography>
        <Typography variant="h2" align="center">
          THE COLLECTION
        </Typography>
        <Typography mt={4} variant="subtitle1" color="darkGray" align="center">
          Rasta Beach Club NFT is a unique digital art collection on the
          Ethereum Blockchain. The Beach Club will be open to all and free to
          mint.
        </Typography>
      </Box>

      <Box justifyContent="flex-end">
        <NftCollection sx={{ marginTop: 15, marginBottom: 15 }} />
      </Box>

      <Grid container component={Container} maxWidth="lg">
        <Grid item md={6} xs={12} align={!isMedium ? "center" : "left"}>
          <Typography color="darkGray" variant="h2" sx={{ fontSize: "70px" }}>
            THE PROJECT
          </Typography>
          <Typography variant="h2">RASTA BEACH CLUB</Typography>
          <Typography
            component="div"
            maxWidth={isLarge ? "420px" : isMedium ? "300px" : "700px "}
            mt={4}
            variant="subtitle1"
            color="darkGray"
            gutterBottom
          >
            The Rasta Beach Club NFT collection is free to mint with a long term
            vision of how we build out a community around the project. Just know
            that to benefit from it you have to participate in it.
          </Typography>
          <Link to="/faq" style={{ textDecoration: "none", color: "inherit" }}>
            <BlackButton
              fullWidth={false}
              sx={{
                width: "200px",
                marginTop: 5,
                marginBottom: "6rem",
                backgroundColor: "#f0a800",
                "&:hover": { backgroundColor: "#8f6504" },
              }}
            >
              Learn More
            </BlackButton>
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "flex", md: "flex" },
            flexDirection: "column",
            justifyContent: "flex-end",
            position: "relative",
            marginTop: !isMedium ? "0" : "250px",
          }}
        >
          {/* Blank Space */}
          <Box
            component="div"
            sx={{
              marginTop: isMedium ? "250px" : isSmall ? "220px" : "100px",
              height: "250px",
            }}
          />

          <Box
            component="img"
            src={imgs.i3}
            alt="collection-nft-1"
            height="auto"
            width={isMedium ? "500px" : isSmall ? "400px" : "300px"}
            sx={{
              position: "absolute",
              left: isMedium ? "30%" : "none",
              right: isMedium ? "none" : isSmall ? "-50px" : "-80px",
            }}
          />

          <Box
            component="img"
            src={imgs.i2}
            alt="collection-nft-1"
            height="auto"
            width={isMedium ? "375px" : isSmall ? "300px" : "230px"}
            sx={{
              position: "absolute",
              left: isMedium ? "-10%" : "50%",
              transform: isMedium ? "" : "translateX(-68%)",
              display: { xs: "block", sm: "block" },
            }}
          />
          <Box
            component="img"
            src={imgs.i1}
            alt="collection-nft-1"
            height="auto"
            width={isMedium ? "275px" : isSmall ? "210px" : "170px"}
            sx={{
              position: "absolute",
              left: isMedium ? "-40%" : isSmall ? "none" : "none",
              right: isSmall ? "70%" : "70%",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TheCollection;
