import React from "react";
import StepBox from "../Stepper/StepBox";
import phaseData from "./roadmapData";
import { Box } from "@mui/material";

export const RoadmapBoxes = ({ isMedium }) => {
  const smallContent = [
    <Box component="div" sx={{ marginY: 10 }} >
      <StepBox
        key="1"
        title="Phase 1"
        isMedium={isMedium}
        bgItem={0}
        checkBoxes={phaseData.phase1}
      />,
      <StepBox
        key="2"
        title="Phase 2"
        isMedium={isMedium}
        bgItem={1}
        checkBoxes={phaseData.phase2}
      />,
      <StepBox
        key="3"
        title="Phase 3"
        isMedium={isMedium}
        bgItem={2}
        checkBoxes={phaseData.phase3}
      />,
    </Box>
  ];

  const mediumContent = [
    <Box component="div" sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      height: "1200px",
      marginY: 15
      }}
    >
      <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
        <StepBox
          key="1"
          title="Phase 1"
          isMedium={isMedium}
          bgItem={0}
          checkBoxes={phaseData.phase1}
        />,
        <StepBox
          key="2"
          title="Phase 2"
          isMedium={isMedium}
          bgItem={1}
          checkBoxes={phaseData.phase2}
        />
      </Box>,
      <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
        <StepBox
          key="3"
          title="Phase 3"
          isMedium={isMedium}
          bgItem={2}
          checkBoxes={phaseData.phase3}
        />,
      </Box>
    </Box>
  ];

  return (
    <div>
      {isMedium ? mediumContent : smallContent}
    </div>
  );
};

export default RoadmapBoxes;
