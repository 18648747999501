import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import AnchorLinkV2 from "react-anchor-link-smooth-scroll-v2";
import { Link } from "react-router-dom";
import Logo from "../../../assets/imgs/logo.png";
import { IS_SALE_IN_PROGRESS } from "../../../config/constants";
import { useConnectWalletContext } from "../../../context/ConnectWalletContext";
import BlackButton from "../../UI/BlackButton";
import SideDrawer from "../SideDrawer/SideDrawer";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import classes from "./Navbar.module.css";
import NavItem from "./NavItem";

const pages = ["About", "Roadmap", "Team"];

const ResponsiveAppBar = () => {
  const [navbar, setNavbar] = useState(false);
  const [open, setOpen] = useState(false);
  const { openConnectWalletModal } = useConnectWalletContext();

  const { active, account } = useWeb3React();

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 5) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  //SideDrawer settings
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <AppBar
      className={
        navbar ? classes["black-navbar"] : classes["transparent-navbar"]
      }
      sx={{ boxShadow: "none" }}
    >
      <Container maxWidth="100%">
        <Toolbar disableGutters sx={{ height: "100%", position: "relative" }}>
          {/* Logo when up("md") */}
          <Link
            to="/home"
            onClick={() =>
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            <Box
              component="img"
              src={Logo}
              maxHeight="5rem"
              sx={{
                display: { xs: "none", lg: "flex" },
                marginRight: 3,
                marginTop: "10px",
                marginBottom: "8px",
              }}
            />
          </Link>

          <Box
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                lg: "none",
              },
            }}
          >
            {/* Logo when down("md") */}
            <Link to="/home">
              <img
                src={Logo}
                width="60px"
                alt="logo"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
                style={{
                  position: "relative",
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
              />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
            {pages.map((page) => (
              <AnchorLinkV2 key={page} href={`#${page.toLowerCase()}`}>
                <NavItem
                  path="/home"
                  text={page}
                  onClick={() => toggleDrawer(false)}
                />
              </AnchorLinkV2>
            ))}
            <NavItem
              path="/faq"
              text="FAQ"
              onClick={() => toggleDrawer(false)}
            />
            <NavItem
              text="Blog"
              link="https://blog.rastabeachclub.com/"
              label="Blog"
              onClick={() => toggleDrawer(false)}
            />
            {IS_SALE_IN_PROGRESS && (
              <NavItem
                path="/mint"
                text="Mint"
                onClick={() => toggleDrawer(false)}
              />
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: {
                xs: "none",
                lg: "flex",
              },
              justifyContent: "center",
            }}
          >
            <SocialMediaIcons marginRight="1.2rem" />
          </Box>

          {/* Black Connect Button */}
          <Box
            sx={{
              display: {
                xs: "none",
                lg: IS_SALE_IN_PROGRESS ? "flex" : "none",
              },
            }}
          >
            <BlackButton
              variant={navbar ? "outlined" : "contained"}
              color={navbar ? "white" : "black"}
              sx={{}}
              onClick={openConnectWalletModal}
            >
              <AccountBalanceWalletOutlinedIcon sx={{ mr: 1 }} />
              {active
                ? `${account.substring(0, 6)}...${account.substring(37)}`
                : "Connect Wallet"}
            </BlackButton>
          </Box>

          {/* Small Wallet Button */}
          <Box
            sx={{
              flexGrow: 0,
              mr: 1,
              display: {
                xs: IS_SALE_IN_PROGRESS ? "flex" : "none",
                lg: "none",
              },
            }}
          >
            <IconButton
              size="large"
              onClick={openConnectWalletModal}
              sx={{ borderRadius: active ? "15px" : "50%", fontSize: "1.2rem" }}
            >
              <AccountBalanceWalletOutlinedIcon
                MenuIcon
                sx={{ fontSize: "1.8rem", mr: active ? 1 : 0 }}
              />
              {active && `${account.substring(0, 8)}`}
            </IconButton>
          </Box>

          {/* Menu Icon Button */}
          <Box sx={{ flexGrow: 0, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="side-drawer"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
            <SideDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              navItems={pages}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
