import { Container, Toolbar, Typography } from "@mui/material";
import Background from "../../assets/imgs/faq/faq-bg.jpg";
import React from "react";
import { Box } from "@mui/system";
import Accordions from "./Accordions/Accordions";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const FAQ = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Container
      maxWidth="100%"
      sx={{
        width: "100%",
        backgroundImage: `url(${Background})`,
        backgroundAttachment: isSmall ? "fixed" : "inherit",
        height: isMedium ? "1170px" : !isSmall ? "1560px" : "100%",
        
        backgroundSize: "3840px 2600px",
        backgroundPosition: "50% 100%",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Toolbar />
      <Toolbar />
      <Container maxWidth="lg">
        <Box paddingY={8}>
          <Typography
            variant="h2"
            align="center"
            sx={{ fontSize: "70px", color: "#ddd" }}
          >
            FAQ
          </Typography>
          <Typography variant="h2" align="center">
            GET THE FACTS
          </Typography>
        </Box>

        <Accordions />
      </Container>
    </Container>
  );
};

export default FAQ;
