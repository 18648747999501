import { CHAIN_ID, PROVIDER_URL } from "../constants";

export const networks = {
  ETH: 1,
  BSC: 56,
  ETH_RINKEBY: 4,
  ETH_GOERLI: 5,
  BSC_TESTNET: 97,
};

export const explorerUrls = {
  transaction: {
    [networks.ETH]: "https://etherscan.io/tx/",
    [networks.BSC]: "https://bscscan.com/tx/",
    [networks.ETH_RINKEBY]: "https://rinkeby.etherscan.io/tx/",
    [networks.ETH_GOERLI]: "https://goerli.etherscan.io/tx/",
    [networks.BSC_TESTNET]: "https://bscscan.com/tx/",
  },
  address: {
    [networks.ETH]: "https://etherscan.io/address/",
    [networks.BSC]: "https://bscscan.com/address/",
    [networks.ETH_RINKEBY]: "https://rinkeby.etherscan.io/address/",
    [networks.ETH_GOERLI]: "https://goerli.etherscan.io/address/",
    [networks.BSC_TESTNET]: "https://bscscan.com/address/",
  },
};

export const addressExplorerUrl = explorerUrls.address[CHAIN_ID];
export const txExplorerUrl = explorerUrls.transaction[CHAIN_ID];

export const nodes = {
  [networks.ETH]: [PROVIDER_URL],
  [networks.BSC]: [
    "https://bsc-dataseed1.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
  ],
  [networks.ETH_RINKEBY]: ["https://rinkeby.infura.io/v3/"],
  [networks.ETH_GOERLI]: ["https://goerli.infura.io/v3/"],
  [networks.BSC_TESTNET]: [
    "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "https://data-seed-prebsc-2-s1.binance.org:8545/",
    "https://data-seed-prebsc-1-s2.binance.org:8545/",
  ],
};

export const networkConfigs = {
  [networks.ETH]: {
    chainId: `0x${networks.ETH.toString(16)}`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "eth",
      decimals: 18,
    },
    rpcUrls: nodes[networks.ETH],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  [networks.BSC]: {
    chainId: `0x${networks.BSC.toString(16)}`,
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "bnb",
      decimals: 18,
    },
    rpcUrls: nodes[networks.BSC],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  [networks.ETH_RINKEBY]: {
    chainId: `0x${networks.ETH_RINKEBY.toString(16)}`,
    chainName: "Rinkeby Test Network",
    nativeCurrency: {
      name: "ETH",
      symbol: "eth",
      decimals: 18,
    },
    rpcUrls: nodes[networks.ETH_RINKEBY],
    blockExplorerUrls: ["https://rinkeby.etherscan.io"],
  },
  [networks.ETH_GOERLI]: {
    chainId: `0x${networks.ETH_GOERLI.toString(16)}`,
    chainName: "Goerli Test Network",
    nativeCurrency: {
      name: "ETH",
      symbol: "eth",
      decimals: 18,
    },
    rpcUrls: nodes[networks.ETH_GOERLI],
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  },
  [networks.BSC_TESTNET]: {
    chainId: `0x${networks.BSC_TESTNET.toString(16)}`,
    chainName: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "bnb",
      decimals: 18,
    },
    rpcUrls: nodes[networks.BSC_TESTNET],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
};
