import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import logo from "../../assets/imgs/logo.png";
import cardBg1 from "../../assets/imgs/mint/card-bg-1.png";
import cardBg2 from "../../assets/imgs/mint/card-bg-2.png";
import cardBg3 from "../../assets/imgs/mint/card-bg-3.png";
import { IS_SALE_IN_PROGRESS, PROVIDER_URL } from "../../config/constants";
import { useConnectWalletContext } from "../../context/ConnectWalletContext";
import BlackButton from "../UI/BlackButton";
import { getMintPerWallet } from "./blockchain";
import mintStyle from "./Mint.module.css";
import Minter from "./Minter";
import MintModal from "./MintModal";

export default function Mint() {
  const [cardBg, setCardBg] = useState(null);
  const { openConnectWalletModal } = useConnectWalletContext();
  const { active } = useWeb3React();
  const [mintModal, setMintModal] = useState({});
  const { title, body, transactionHash, dismissOnBackdrop } = mintModal;
  const [mintPerWallet, setMintPerWallet] = useState(10);
  const navigate = useNavigate();

  const cardBgs = [cardBg1, cardBg2, cardBg3];

  const handleMintPerWallet = async () => {
    const mintPerWallet = await getMintPerWallet(PROVIDER_URL);
    setMintPerWallet(mintPerWallet);
  };

  useEffect(() => {
    if (!IS_SALE_IN_PROGRESS) {
      navigate("/");
    }

    const cardBgIndex = Math.floor(Math.random() * cardBgs.length);
    setCardBg(cardBgs[cardBgIndex]);
    handleMintPerWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setMintModal({});
  };

  return (
    <Container className={mintStyle["container-mint"]}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: "space-around",
          width: 800,
        }}
      >
        <Box width={350} height={420} position="relative">
          <img
            src={cardBg}
            className={mintStyle["card-img"]}
            alt="Card Background"
          />
          <img
            src={logo}
            className={mintStyle["card-avatar"]}
            alt="Card Avatar"
          />
        </Box>
        <Box
          width={300}
          sx={{
            textAlign: { xs: "center", md: "left" },
            mb: { xs: 5, md: 0 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              lineHeight: 0.75,
              mb: 3,
            }}
          >
            FREE MINT YOUR RASTA BEACH CLUB NFT
          </Typography>
          <Typography mb={3}>
            Connect your wallet to start minting. Allowed max{" "}
            <b>{mintPerWallet} NFTs</b> per wallet including previous mints.
          </Typography>
          {active ? (
            <Minter setMintModal={setMintModal} mintPerWallet={mintPerWallet} />
          ) : (
            <BlackButton onClick={openConnectWalletModal}>
              Connect Wallet
            </BlackButton>
          )}
        </Box>
      </Box>
      <MintModal
        title={title}
        body={body}
        transactionHash={transactionHash}
        dismissOnBackdrop={dismissOnBackdrop}
        handleCloseModal={handleCloseModal}
      />
    </Container>
  );
}
