import React from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordion = ({ summary, details, expanded, onChange, children }) => {
  return (
    <MuiAccordion
      expanded={expanded}
      onChange={onChange}
      sx={{
        backgroundColor: "#fff",
        marginTop: 1,
        marginBottom: 1,
        borderRadius: "10px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="black" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="black.main" variant="h3">
          {summary.toUpperCase()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {details && <Typography color="black.main" variant="body1">
          {details}
        </Typography>}
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
