import { CssBaseline } from "@mui/material";
import LandingPage from "./components/LandingPage/LandingPage";
import Layout from "./components/Layout/Layout";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router";
import FAQ from "./components/FAQ/FAQ";
import { useEffect } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import Mint from "./components/Mint/Mint";
import ScrollToTop from "./components/UI/ScrollToTop";

function App() {
  const { error, deactivate } = useWeb3React();

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      window.localStorage.removeItem("walletconnect");
      deactivate();
    }
  }, [error, deactivate]);

  return (
    <>
      <CssBaseline enableColorScheme />
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route exact path="/home" element={<LandingPage />}></Route>

          <Route exact path="/mint" element={<Mint />}></Route>
          <Route exact path="/faq" element={<FAQ />}></Route>
          <Route path="*" element={<Navigate to="/home" />}></Route>
        </Routes>
      </Layout>
    </>
  );
}

export default App;
