import { Box, Typography } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import {
  WALLETCONNECT_CONNECTOR_ID,
  WALLETCONNECT_LOGO,
} from "../../config/constants";
import { connect } from "../../config/wallet/connector";
import connectWalletStyle from "./ConnectWallet.module.css";

export default function WalletConnect({ handleCloseModal }) {
  const { activate } = useWeb3React();

  const handleConnect = async () => {
    await connect(WALLETCONNECT_CONNECTOR_ID, activate);
    handleCloseModal();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <button
        className={connectWalletStyle["wallet-button"]}
        onClick={handleConnect}
      >
        <img
          src={WALLETCONNECT_LOGO}
          alt="WalletConnect"
          className={connectWalletStyle["wallet-logo"]}
        />
        <Typography variant="button">Wallet Connect</Typography>
      </button>
    </Box>
  );
}
