import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ path, text, onClick, link, label, sx, download }) => {
  if (link !== undefined) {
    return (
      <a
        href={link}
        target="_blank"
        aria-label={label}
        rel="noreferrer"
        download={download ? true : null}
      >
        <Button
          onClick={onClick}
          size="large"
          variant="text"
          color="white"
          sx={{ my: 2, color: "white", display: "block", ...sx }}
        >
          {text}
        </Button>
      </a>
    );
  }

  return (
    <Link to={{ pathname: path }}>
      <Button
        onClick={onClick}
        size="large"
        variant="text"
        color="white"
        sx={{ my: 2, color: "white", display: "block", ...sx }}
      >
        {text}
      </Button>
    </Link>
  );
};

export default NavItem;
