import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CircleWave2 from "../../../assets/imgs/landing-page/circle-wave2.png";
import classes from "./Roadmap.module.css";
import RoadmapBoxes from "./Slideshow/RoadmapBoxes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Roadmap = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Container
        id="roadmap"
        maxWidth="100%"
        component="div"
        className={classes["full-width"]}
        sx={{
          position: "relative",
          backgroundColor: "#f6f2d3",
          overflowX: "hidden",
          paddingLeft: 0,
          margin: 0,
        }}
      >
        <Box
          component="div"
          maxWidth="100%"
          sx={{
            paddingLeft: 0,
            m: 0,
            width: "100vw",

            backgroundImage: `url(${CircleWave2})`,
            backgroundRepeat: false,
            backgroundSize: "3800px",
            backgroundPosition: "-5% 70%",
            zIndex: -1,
          }}
        >
          <Container maxWidth="lg" top="0" left="0" sx={{ paddingTop: 20 }}>
            <Box textAlign={isMedium ? "left" : "center"}>
              <Typography
                color="darkGray"
                variant="h2"
                sx={{ fontSize: "70px" }}
              >
                OUR
              </Typography>
              <Typography variant="h2" color="black.main">
                ROADMAP
              </Typography>
              <Typography
                component="div"
                maxWidth="700px"
                mt={4}
                variant="subtitle1"
                color="black.main"
                gutterBottom
                alignSelf="center"
                marginX={!isMedium ? "auto" : "none"}
                sx={{
                  display: "flex",
                  justifyContent: !isMedium ? "flex-start" : "center",
                }}
              >
                The primary goal of any NFT project’s roadmap is to provide a
                picture of long term value for the NFT and those who choose to
                participate in specific projects. We’ve been thoughtful about
                how we want to engage with our Rasta Beach Club community, what
                we want to represent, our core philosophy of fairness and
                inclusiveness, and doing as much as we can in a big complicated
                world. Here is our roadmap.
              </Typography>
            </Box>
            <Box>
              <RoadmapBoxes isMedium={isMedium} isSmall={isSmall} />
            </Box>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default Roadmap;
