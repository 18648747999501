import CloseIcon from "@mui/icons-material/Close";
import { Container, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import AnchorLinkV2 from "react-anchor-link-smooth-scroll-v2";
import { IS_SALE_IN_PROGRESS } from "../../../config/constants";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import SideDrawerItem from "./SideDrawerItem";

export default function SideDrawer({ open, toggleDrawer, navItems }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const list = (
    <Container
      sx={{
        paddingX: 0,
        margin: 0,
        height: "100vh",
        position: "relative",
        width: isSmall ? "100vw" : "450px",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: "5%", right: "5%" }}
        onClick={toggleDrawer(false)}
      >
        <CloseIcon sx={{ fontSize: "2rem" }} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: isSmall ? "60%" : "50%",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "0",
          width: "100%",
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {navItems.map((text, index) => (
            <AnchorLinkV2 key={index} href={`#${text.toLowerCase()}`}>
              <SideDrawerItem text={text} path="/home" />
            </AnchorLinkV2>
          ))}
          <SideDrawerItem text="FAQ" path="/faq" />
          <SideDrawerItem text="Blog" link="https://blog.rastabeachclub.com/" />
          {IS_SALE_IN_PROGRESS && <SideDrawerItem text="Mint" path="/mint" />}
        </List>

        <SocialMediaIcons sx={{ marginY: 3 }} align="center" />
      </Box>
    </Container>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        variant="temporary"
        open={open && !isLarge}
        onClose={toggleDrawer(false)}
        sx={{ width: "100vw", height: "100vh" }}
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
          },
        }}
      >
        {list}
      </Drawer>
    </div>
  );
}
