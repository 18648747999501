import { Box } from "@mui/material";
export const UncheckedCircle = () => {
  return (
    <Box
      component="div"
      sx={{
        borderRadius: "50%",
        backgroundColor: "darkgrey",
        height: "22px",
        width: "22px",
        minHeight: "22px",
        minWidth: "22px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 2,
      }}
    >
      <Box sx={{ borderRadius: "50%", backgroundColor: "primary.main", height: "18px", width: "18px" }} />
    </Box>
  );
};