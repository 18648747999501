import { Box } from "@mui/system";
import React from "react";

const Nft = ({ img, key }) => {
  return (
    <Box
      key={key}
      component="img"
      src={img}
      sx={{ borderRadius: "5px", width: "165px" }}
    ></Box>
  );
};

export default Nft;
