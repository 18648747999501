import { BigNumber } from "ethers";
import Web3 from "web3";
import nftAbi from "../../config/abis/nft.json";
import getSignature from "../../config/api/getSignature";
import {
  NFT_CONTRACT_ADDRESS,
  NFT_PRESALE_MINT_METHOD,
  NFT_PUBLIC_MINT_METHOD,
} from "../../config/constants";

const getContract = (providerUrl) => {
  const web3 = new Web3(providerUrl);
  const contract = new web3.eth.Contract(nftAbi, NFT_CONTRACT_ADDRESS);
  return contract;
};

export const mintEstimateGas = async (
  account,
  library,
  mintQuantity,
  setMintModal
) => {
  const contract = getContract(library.currentProvider);

  const result = await Promise.all([
    contract.methods.price().call(),
    getIsPrivateSale(library),
    contract.methods.owner().call(),
  ]);
  const [cost, isPrivateSale, owner] = result;
  const costWithQuantity = BigNumber.from(cost).mul(mintQuantity);

  const method = isPrivateSale
    ? NFT_PRESALE_MINT_METHOD
    : NFT_PUBLIC_MINT_METHOD;

  let value = costWithQuantity.toString();
  if (account === owner) {
    value = "0";
  }

  const arg = [mintQuantity];
  if (isPrivateSale) {
    let signature = null;
    try {
      signature = await getSignature(account);
    } catch (error) {
      if (!signature) {
        setMintModal({
          title: "Unable to mint",
          body: "You are not whitelisted on the presale.",
        });
        return;
      }
      arg.push(signature);
    }
  }

  return contract.methods[method](...arg).estimateGas({
    from: account,
    value: value,
  });
};

export const mint = async (account, library, mintQuantity, setMintModal) => {
  const contract = getContract(library.currentProvider);
  const result = await Promise.all([
    contract.methods.price().call(),
    getIsPrivateSale(library),
    contract.methods.owner().call(),
  ]);
  const [cost, isPrivateSale, owner] = result;
  const costWithQuantity = BigNumber.from(cost).mul(mintQuantity);

  const arg = [mintQuantity];
  if (isPrivateSale) {
    let signature = null;
    try {
      signature = await getSignature(account);
    } catch (error) {
      if (!signature) {
        setMintModal({
          title: "Unable to mint",
          body: "You are not whitelisted on the presale.",
        });
        return;
      }
      arg.push(signature);
    }
  }

  const method = isPrivateSale
    ? NFT_PRESALE_MINT_METHOD
    : NFT_PUBLIC_MINT_METHOD;

  let value = costWithQuantity.toString();
  if (account === owner) {
    value = "0";
  }

  await contract.methods[method](...arg)
    .send({
      from: account,
      value: value,
    })
    .on("receipt", (receipt) => {
      setMintModal({
        title: "Success",
        body: receipt.transactionHash,
        transactionHash: receipt.transactionHash,
      });
    })
    .on("error", (error, receipt) => {
      if (receipt) {
        setMintModal({
          title: "Failed",
          body: receipt.transactionHash,
          transactionHash: receipt.transactionHash,
        });
      }
    });
};

export const getMintPerWallet = async (providerUrl) => {
  const mintPerWallet = await getContract(providerUrl).methods.mintPerWallet().call();
  return mintPerWallet;
}

export const getIsPrivateSale = async (library) => {
  const isPrivateSale = await getContract(library.currentProvider).methods.isPreSale().call();
  return isPrivateSale;
};

export const getIsPublicSale = async (library) => {
  const isPublicSale = await getContract(library.currentProvider).methods.isPublicSale().call();
  return isPublicSale;
};

export const getMaxSupply = async (library) => {
  const maxSupply = await getContract(library.currentProvider).methods.maxSupply().call();
  return maxSupply;
};

export const getTotalSupply = async (library) => {
  const totalSupply = await getContract(library.currentProvider).methods.totalSupply().call();
  return totalSupply;
};

export const getContractData = async (account, library) => {
  const result = await Promise.all([
    getIsPrivateSale(library),
    getIsPublicSale(library),
    getMaxSupply(library),
    getTotalSupply(library),
  ]);

  const data = {
    isPrivateSale: result[0],
    isPublicSale: result[1],
    maxSupply: Number(result[2]),
    totalSupply: Number(result[3]),
  };

  return data;
};

export const getMintButtonData = async (contractData) => {
  const { isPrivateSale, isPublicSale, maxSupply, totalSupply } =
    contractData;

  if (isPrivateSale || isPublicSale) {
    if (totalSupply < maxSupply) {
      return {
        canMint: true,
        text: "Mint",
      };
    }
    return {
      canMint: false,
      text: "Out of Stock",
    };
  }
  return {
    canMint: false,
    text: "Closed",
  };
};
