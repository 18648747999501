import { CircularProgress } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import React, { useEffect, useState } from "react";
import BlackButton from "../UI/BlackButton";
import {
  getContractData,
  getMintButtonData,
  mint,
  mintEstimateGas,
} from "./blockchain";
import mintStyle from "./Mint.module.css";

export default function Minter({ setMintModal, mintPerWallet }) {
  const [mintQuantity, setMintQuantity] = useState(mintPerWallet);
  const [isLoading, setIsLoading] = useState(true);
  const [mintButtonData, setMintButtonData] = useState(false);

  const { active, account, library } = useWeb3React();

  const refreshData = async () => {
    const data = await getContractData(account, library);
    handleCanMint(data);
    setIsLoading(false);
  };

  const getData = async () => {
    if (active && account) {
      refreshData();
    }
    setIsLoading(false);
  };

  const handleCanMint = async (contractData) => {
    setMintButtonData(await getMintButtonData(contractData));
  };

  const handleMint = async () => {
    setIsLoading(true);
    setMintModal({
      title: "Waiting for Confirmation",
      body: <CircularProgress color="white" />,
      dismissOnBackdrop: false,
    });
    await mintEstimateGas(account, library, mintQuantity, setMintModal)
      .then(async () => {
        await mint(account, library, mintQuantity, setMintModal)
          .catch((error) => {
            setMintModal({
              title: "Error",
              body: error.message,
            });
          })
          .finally(refreshData);
      })
      .catch((error) => {
        let message = error.message.match(/"message": "(.+)"/).pop();
        if (message.includes("You have reached your quota to mint")) {
          message = "You have reached / exceeded your quota to mint";
        }
        setMintModal({
          title: "Error",
          body: message,
        });
      })
      .finally(refreshData);
  };

  const handleChangeInput = async (e) => {
    if (!e.target.value) {
      setMintQuantity("");
      return;
    }
    const value = BigNumber.from(e.target.value.replace(/[^\d]/gi, ""));
    if (value.eq(0)) {
      setMintQuantity(1);
      return;
    }
    if (value.gt(mintPerWallet)) {
      return;
    }
    setMintQuantity(value.toString());
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, account]);

  return (
    <>
      <input
        id="quantity-nft"
        className={mintStyle["minter-input"]}
        disabled={isLoading || !mintButtonData.canMint}
        value={mintQuantity}
        onChange={handleChangeInput}
      />
      <BlackButton
        onClick={handleMint}
        disabled={isLoading || !mintButtonData.canMint}
        className={mintStyle["minter-button"]}
      >
        {(isLoading ? "Loading..." : mintButtonData.text) || "Loading..."}
      </BlackButton>
    </>
  );
}
